.maintenancePage {
  text-align: center;
  padding: 10%;
  font: 20px Helvetica, sans-serif;
  color: #333;
}
h1 {
  font-size: 50px;
  margin: 0;
}
